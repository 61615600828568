import type { ElementRef } from "react";
import { useMemo } from "react";
import { forwardRef } from "react";
import { Link as RouterLink, type To } from "react-router-dom";
import type { BoxProps } from "@chakra-ui/react";
import { Center } from "@chakra-ui/react";
import { chakra, Badge, Text } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FaIcon } from "@stordco/fe-components";

type Ref = ElementRef<typeof ChakraRouterLink>;
type Props = Omit<BoxProps, "children"> & {
  to: To;
  colorScheme: "red" | "mango" | "magenta";
  count: number;
  badgeLabel?: string;
  icon?: IconDefinition;
  text?: string;
};

const ChakraRouterLink = chakra(RouterLink);

export const AlertLink = forwardRef<Ref, Props>(function Alert(
  { to, colorScheme, count, badgeLabel, icon, text, ...props },
  ref,
) {
  const {
    bgColor,
    darkBgColor,
    hoverBgColor,
    darkHoverBgColor,
    iconColor,
    iconBgColor,
    darkIconColor,
    darkIconBgColor,
  } = useMemo(() => {
    switch (colorScheme) {
      case "red":
        return {
          bgColor: transparentize("red.50", 0.5),
          darkBgColor: transparentize("red.200", 0.16),
          hoverBgColor: "red.50",
          darkHoverBgColor: transparentize("red.200", 0.32),
          iconColor: "red.500",
          iconBgColor: "red.50",
          darkIconColor: "red.200",
          darkIconBgColor: transparentize("red.200", 0.16),
        };
      case "mango":
        return {
          bgColor: transparentize("mango.50", 0.5),
          darkBgColor: transparentize("mango.50", 0.16),
          hoverBgColor: "mango.50",
          darkHoverBgColor: transparentize("mango.200", 0.32),
          iconColor: "mango.700",
          iconBgColor: "mango.100",
          darkIconColor: "mango.200",
          darkIconBgColor: transparentize("mango.200", 0.16),
        };
      case "magenta":
        return {
          bgColor: transparentize("magenta.50", 0.5),
          darkBgColor: transparentize("magenta.50", 0.16),
          hoverBgColor: "magenta.50",
          darkHoverBgColor: transparentize("magenta.200", 0.32),
          iconColor: "magenta.700",
          iconBgColor: "magenta.100",
          darkIconColor: "magenta.200",
          darkIconBgColor: transparentize("magenta.200", 0.16),
        };
    }
  }, [colorScheme]);

  return (
    <ChakraRouterLink
      ref={ref}
      to={to}
      display="flex"
      alignItems="center"
      gap={3}
      px={4}
      py={2}
      bgColor={bgColor as any}
      _hover={{
        bgColor: hoverBgColor,
      }}
      _focus={{
        outline: "none",
        bgColor: hoverBgColor,
      }}
      _dark={{
        bgColor: darkBgColor,
        _hover: {
          bgColor: darkHoverBgColor,
        },
        _focus: {
          bgColor: darkHoverBgColor,
        },
      }}
      transitionDuration="fast"
      transitionProperty="background"
      rounded="base"
      whiteSpace="nowrap"
      {...props}
    >
      <Text textStyle="title">{new Intl.NumberFormat().format(count)}</Text>

      {badgeLabel ? (
        <Badge colorScheme={colorScheme}>{badgeLabel}</Badge>
      ) : null}

      {icon ? (
        <Center
          boxSize={6}
          rounded="base"
          bgColor={iconBgColor}
          _dark={{
            bgColor: darkIconBgColor,
          }}
        >
          <FaIcon
            icon={icon}
            color={iconColor}
            _dark={{ color: darkIconColor }}
          />
        </Center>
      ) : null}

      {text ? <Text>{text}</Text> : null}
    </ChakraRouterLink>
  );
});
