import { useQuery } from "@tanstack/react-query";

import { isBaseError, useApi } from "@stordco/fe-components";

import type { DayOfWeek } from "../../features/NetworkDetails/Facilities/constants";
import type {
  BackorderStrategy,
  OrderRoutingStrategy,
} from "../../features/Automation/types";
import type { MinimumStockLevelType } from "../../hooks/useItemDetails";
import type { ForecastPeriod } from "../../features/InventoryPlanning/queries";

export const useNetwork = ({ networkId }: { networkId: string }) => {
  const api = useApi();

  return useQuery({
    queryKey: ["Networks", networkId],
    queryFn: () => api<{ data: Network }>(`/bff/v1/networks/${networkId}`),
    select: (response) => response.data,
    retry: (failureCount, error) => {
      if (
        isBaseError(error) &&
        (error.status === 404 || error.status === 400)
      ) {
        return false;
      }

      return failureCount < 3;
    },
  });
};

// These types are largely copied from apps/admin/src/hooks/useNetworks.ts
// But with some differences!
export type Network = {
  tenant_id: string;
  network_id: string;
  company_id: string;
  company: Company;
  name: string;
  manual_order_creation: boolean | null;
  stord_email: string;
  trade_connections: TradeConnection[];
  network_configurations: NetworkConfiguration[];
  units_of_measure: UnitOfMeasure[];

  address_id: string | null;
  address: Address | null;
  timezone: string | null;
};

export interface Company {
  company_id: string;
  name: string;

  // There are other fields, add them as needed
  email: string;
  phone: string;
}

export type NetworkConfiguration = {
  description: string;
  label: string;
  enabled: boolean;
} & (
  | {
      name: "backorders";
      metadata: { strategy: BackorderStrategy | null };
    }
  | {
      name: "split_order";
      metadata: { max_number_of_outbounds?: number };
    }
  | {
      name: "returns_admin";
      metadata: {
        merchant_id: string | null;
      };
    }
  | {
      name: "returns";
      metadata: {
        cx_portal_mode: "disabled" | "self_service" | "requests_only";
        reasons: string[];
      };
    }
  | {
      name: "inventory_replenishment";
      metadata: {
        default_minimum_stock_level: {
          value: number;
          type: MinimumStockLevelType;
        } | null;
        default_lead_time: number | null;
        default_expiration_threshold: number | null;
        purchasing_outlook: number | null;
      };
    }
  | {
      name: "hold_order";
      metadata: {
        duration?: number | null;
        duration_unit?: "day" | "hour" | "minute" | "indefinite";
      };
    }
  | {
      name: "order_routing";
      metadata: {
        strategy: OrderRoutingStrategy | null;
      };
    }
  | {
      name: "consolidate_fulfillments";
      metadata: {
        duration?: number;
        duration_unit?: "minute" | "hour" | "day";
      };
    }
  | {
      name: "demand_planning_forecast_period";
      metadata: {
        forecast_period: ForecastPeriod;
      };
    }
  | {
      name:
        | "desired_delivery_date"
        | "enable_order_enrichment_automations"
        | "enable_force_orders_automations"
        | "enable_automations_cs_service_method"
        | "enable_automations_cs_ship_option"
        | "enable_third_party_billing_automations"
        | "enable_automations_cs_bdot"
        | "enable_ship_full_cheapest"
        | "enable_orchestration"
        | "enable_backorder_automations"
        | "hold_order_automations_ui"
        | "order_modification_automations"
        | "enable_shelf_life_compliance_automations"
        | "wms_cancel_protocol"
        | "address_validation_exceptions"
        | "enable_minimum_address_requirement"
        | "work_orders"
        | "lot_approvals"
        | "bundle_availability_calculation"
        | "network_delivery_tracking"
        | "channel_reservations"
        | "verify_channel"
        | "product_listings"
        | "show_product_images"
        | "unit_id_search"
        | "demand_planning"
        | "demand_planning_ui"
        | "consumer_experience"
        | "manual_confirmations"
        | "manual_inventory_advice"
        | "shipment_protection_admin";
      metadata: {};
    }
);

interface UnitOfMeasure {
  name: string;
  short_code: string;
}

interface TradeConnection {
  trade_connection_id: string;
  allocated_square_feet: null | string;
  facility: Facility;
  facility_display_name: null | string;
  facility_id: string;
  network_id: string;
  rank: number;
  routing_adder: null | string;
  wms_id: null | string;
  service_level_agreements: ServiceLevelAgreement[];
}

interface ServiceLevelAgreement {
  service_level_agreement_id: string;
  trade_connection_id?: string;
  facility_id?: string;
  fulfillment_type: string;
  cutoff_time: string;
  days_to_complete: number;
  updated_by?: string;
}

interface Facility {
  tenant_id: string;
  facility_id: string;
  address: Address;
  alias: string;
  company_id: string;
  email: null | string;
  facility_display_name: null | string;
  holidays: string[] | null;
  inbound_docks: number | null;
  location_description: null;
  name: string;
  operation_schedules: OperationSchedule[];
  outbound_docks: number | null;
  timezone: string;
  total_square_feet: null | string;
  type: "enf" | "sdn" | "sfn" | "spn" | "warehouse";
  warehouse_management_system_id: null | string;
  wifi_enabled: boolean | null;
}

interface OperationSchedule {
  operation_schedule_id: string;
  facility_id: string;
  day: DayOfWeek;
  start_time: string;
  end_time: string;
}

export interface Address {
  address_id: string;
  address_type: string | null;
  company: string | null;
  country_code: string | null;
  country_subdivision_code: string | null;
  latitude: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  locality: string | null;
  longitude: string | null;
  name: string | null;
  normalized_address: string | null;
  normalized_country_code: string | null;
  normalized_country_subdivision_code: string | null;
  normalized_line1: string | null;
  normalized_line2: string | null;
  normalized_locality: string | null;
  normalized_name: string | null;
  normalized_postal_code: string | null;
  postal_code: string | null;
  verification_status: string | null;
}
