import { useQuery } from "@tanstack/react-query";

import { isBaseError, useApi } from "@stordco/fe-components";

import type { ShipmentType } from "../useShipmentTypes";
import { extractUUID } from "../../utils/extractUuid";

export function useOrderDetails<T extends OrderDetails = OrderDetails>({
  id,
}: {
  id: string;
}) {
  const api = useApi();

  const orderId = extractUUID(id);

  return useQuery({
    queryKey: ["orderDetails", orderId],
    queryFn: () =>
      api.get<{ data: T }>({
        url: `/bff/v1/order_details/${orderId}`,
      }),
    select: (response) => response.data,
    staleTime: 1000 * 60 * 1,
    retry: (failureCount, error) => {
      if (isBaseError(error) && error.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });
}

export const serialIdentifiers = ["sim", "imei", "serial_number"] as const;
export type SerialIdentifierType = (typeof serialIdentifiers)[number];

export const getSerialIdentifiersFromFacilityActivities = (
  facilityActivities: FacilityActivity[],
  facilityActivityId: string | undefined,
  itemId: string,
) => {
  const facilityActivity = facilityActivities.find(
    (facilityActivity) =>
      facilityActivity.facility_activity_id === facilityActivityId,
  );

  return (
    facilityActivity?.serial_identifiers.find((si) => si.item_id === itemId)
      ?.serial_identifiers || []
  );
};

export const hasSerialNumbers = (facilityActivities: FacilityActivity[]) =>
  facilityActivities.some((activity) =>
    activity.serial_identifiers.some((serial) =>
      serial.serial_identifiers.some((identifier) => identifier.serial_number),
    ),
  );

export const hasSims = (facilityActivities: FacilityActivity[]) =>
  facilityActivities.some((activity) =>
    activity.serial_identifiers.some((serial) =>
      serial.serial_identifiers.some((identifier) => identifier.sim),
    ),
  );

export const hasImeis = (facilityActivities: FacilityActivity[]) =>
  facilityActivities.some((activity) =>
    activity.serial_identifiers.some((serial) =>
      serial.serial_identifiers.some((identifier) => identifier.imei),
    ),
  );

// Technically some of these can't be on a specific order type but they're in the response
type OrderBase = {
  actions: Actions;
  automation_results: unknown;
  backorder_strategy_source: string | null;
  backorder_strategy: string | null;

  bill_to_customer_id: string | null;
  /** Only exists when bill_to_customer_id is set */
  bill_to_customer?: Customer & { address?: Address };

  business_days_of_transit: number | null;
  carrier_name: string | null;
  carrier_scac: string | null;
  carrier_service_method: string | null;
  carrier_ship_option: string | null;

  channel_id: string | null;
  /** Only exists when channel_id is set */
  channel?: Channel;

  charged_shipping_cost: number | null;
  cost_to_ship: number | null;
  custom_reference: string | null;
  cutoffs: {
    order_change: boolean | null;
    order_line_change: boolean | null;
    order_cancel: boolean | null;
  };
  delivered_at: string | null;
  delivery_tracking: boolean;
  desired_delivery_date: string | null;
  destination_address: DestinationAddress | null;
  do_not_ship_before: string | null;
  exceptions: Exception[];
  expected_received_at: string | null;
  expected_shipped_at: string | null;
  external_order_tags: string[] | null;
  external_posted_at: string | null;
  facility_activities: FacilityActivity[];
  gift_message: string | null;
  inserted_at: string;
  integration_metadata: unknown;
  must_ship_by: string | null;
  network_id: string;
  notes: string | null;
  order_completion: null | {
    completed_at: string;
    completed_by: string;
    completed_by_name: string;
    notes: string;
  };
  order_documents: FacilityActivityDocument[];
  order_event_count: number;
  order_id: string;
  order_number: string;
  origin_address: Address | null;
  preferred_destination_facilities: string[] | null;
  preferred_origin_facilities: string[] | null;
  planned_carrier_service_method: string | null;
  price: string | null;
  print_option_1: string | null;
  print_option_2: string | null;
  print_option_3: string | null;
  priority: number;
  receipt_confirmations: ReceiptConfirmation[];
  received_at: string | null;
  saturday_delivery: boolean | null;
  scheduled_pick_up: string | null;
  ship_to_customer_id: string | null;
  /** Only exists when ship_to_customer_id is set */
  ship_to_customer?: Customer;
  shipment_confirmations: ShipmentConfirmation[];
  shipment_type: ShipmentType | null;
  shipped_at: string | null;
  shipping_code: string | null;

  signature_required: SignatureRequired | null;
  sold_to_customer_id: string | null;
  /** Only exists when sold_to_customer_id is set */
  sold_to_customer?: Customer;
  status: KnownOrderStatus;
  supplier_id: string | null;
  /** Only exists when supplier_id is set */
  supplier?: Supplier;
  tags: OrderTag[];
  type: "sales" | "purchase" | "transfer";
  updated_at: string;
};

export interface SalesOrder extends OrderBase {
  type: "sales";
  destination_address: DestinationAddress;
  sales_order_lines: SalesOrderLine[];
  financials?: SalesOrderFinancials;

  fulfillment_consolidation:
    | { status: "ineligible" }
    | {
        status: "opportunity";
        fulfillment_consolidation: { expires_at: string };
      }
    | {
        status: "consolidated";
        parent: {
          facility_activity_id: string;
          order_id: string;
          order: {
            order_number: string;
          };
        };
        children: Array<{
          facility_activity_id: string;
          order_id: string;
          order: { order_number: string };
        }>;
      };
}

export interface PurchaseOrder extends OrderBase {
  type: "purchase";
  purchase_order_line_completions: Array<{
    order_line_id: string;
  }>;
  purchase_order_lines: PurchaseOrderLine[];
}

export interface TransferOrder extends OrderBase {
  type: "transfer";
  destination_facility_alias: string | null;
  destination_facility_display_name: string | null;
  multi_node: boolean;
  origin_facility_alias?: string;
  origin_facility_display_name?: string;
  transfer_order_lines?: TransferOrderLine[];
}

export type OrderDetails = SalesOrder | PurchaseOrder | TransferOrder;

type Actions = {
  cancel: boolean;
  edit_order_lines: boolean;
  order_line_actions: Record<string, Record<string, boolean>>;
  reprocess: boolean;
  update_destination: boolean;
};

type Customer = {
  contact_name: string;
  contact_phone: string;
  customer_id: string;
  email: string;
  name: string;
  network_id: string;
};

type Channel = {
  category: string;
  channel_id: string;
  default_priority: number;
  display_name: string;
  network_id: string;
  status: string;
  type: string;
};

type Supplier = {
  address_id?: string;
  name?: string;
};

export type Address = {
  country_code?: string;
  country_subdivision_code?: string;
  line1?: string;
  line2?: string;
  company?: string;
  locality?: string;
  name?: string;
  postal_code?: string;
  normalized_address?: string;
  normalized_name?: string;
  normalized_line1?: string;
  normalized_line2?: string;
  normalized_locality?: string;
  normalized_postal_code?: string;
  normalized_country_code?: string;
  normalized_country_subdivision_code?: string;
};

type DestinationAddress = Address & {
  bypass_address_validation: boolean | null;
  verification_status: "valid" | "invalid" | null;
};

interface FacilityActivityDocument {
  document_id: string;
  document_type: string;
  file_name: string;
}

export type ReceiptConfirmation = {
  receipt_confirmation_id: string;
  received_at: string;
  confirmation_number: string;
  facility_activity_id: string;
  activity_number: string;
  activity_status: string;
  receipt_confirmation_line_items: ReceiptConfirmationLineItem[];
};

export type ReceiptConfirmationLineItem = {
  item_id: string;
  sku: string;
  unit_of_measure: string;
  actual: string;
  damages: string;
  lot_number: string;
  expiration: string;
  handling_unit: string;
  name: string;
};

export type SalesOrderLine = {
  automation_id?: string;
  automation_name?: string;
  charged_shipping?: number;
  cost_to_ship?: number;
  expected_shipped_at?: string;
  expected_sla_date?: string;
  facility_activity_id?: string;
  facility_id?: string;
  financials?: SalesOrderLineFinancials;
  image_urls?: string[];
  integration_metadata?: any;
  is_digital?: boolean;
  kit_id: string | null;
  kit_sku: string | null;
  listing_id: string | null;
  listing: string | null;
  name: string | null;
  network_id: string;
  order_id: string;
  order_line_items: OrderLineItem[];
  product_brand?: string;
  product_name: string;
  quantity: string;
  sale_price?: number;
  sales_order_line_id: string;
  shipped_at?: string;
  skip_inventory_check?: string;
  split_from?: string;
  status: KnownOrderStatus;
};

export type PurchaseOrderLine = {
  charged_shipping?: number;
  cost_to_ship?: number;
  expected_received_at?: string;
  facility_activity_id?: string;
  facility_id?: string;
  image_urls?: string[];
  integration_metadata?: any;
  kit_id: string;
  kit_sku: string;
  name?: string;
  network_id: string;
  order_id: string;
  order_line_items: OrderLineItem[];
  order_line_number: number;
  product_brand?: string;
  product_name: string;
  purchase_order_line_id: string;
  purchase_price: string | null;
  quantity: string;
  received_at?: string;
  split_from?: string;
  status: KnownOrderStatus;
};

export type TransferOrderLine = {
  cost_to_ship?: number;
  facility_id?: string;
  image_urls?: string[];
  inbound_facility_activity_id?: string;
  integration_metadata?: any;
  kit_id: string;
  kit_sku: string;
  name?: string;
  network_id: string;
  order_id: string;
  order_line_items: OrderLineItem[];
  outbound_facility_activity_id?: string;
  product_brand?: string;
  product_name: string;
  product_sku: string;
  quantity: string;
  shipped_at?: string;
  split_from?: string;
  status: KnownOrderStatus;
  transfer_order_line_id: string;
};

export type OrderLineItem = {
  handling_unit: string;
  item_id: string;
  item_quantity: string;
  item_sku: string;
  lot_number: string;
  order_line_item_id: string;
  product_brand?: string;
  product_name: string;
  unit: string;
};

export type FacilityActivity = {
  activity_number: string | null;
  activity_type: "inbound" | "outbound";
  completed_at?: string;
  expected_completion_at?: string;
  expected_delivered_at: string | null;
  expected_sla_date?: string;
  facility_activity_id: string;
  facility_alias: string;
  facility_display_name?: string;
  facility_id: string;
  inserted_at: string;
  operational_status: KnownOrderStatus;
  order_custom_reference: string | null;
  shipment: Shipment; // should be list
  serial_identifiers: Array<{
    item_id: string;
    serial_identifiers: Array<{
      serial_number?: string;
      sim?: string;
      imei?: string;
    }>;
  }>;
};

type Shipment = {
  bol_numbers?: string[] | null;
  mode: string | null;
  pro_numbers?: string[] | null;
  tracking_links?: string[] | null;
  tracking_numbers?: string[] | null;
};

export type ShipmentConfirmation = {
  activity_number: string;
  activity_status: string;
  carrier_estimated_delivery_at: string | null;
  carrier_service_method: string | null;
  confirmation_number: string;
  facility_activity_id: string;
  facility_address: Address;
  facility_alias: string;
  facility_display_name: string;
  shipment_confirmation_id: string;
  shipment_confirmation_line_items: ShipmentConfirmationLineItem[];
  shipment_status:
    | null
    | "label_printed"
    | "in_transit"
    | "out_for_delivery"
    | "delivered"
    | "exception";
  shipped_at: string;
  tracking_link: string;
  tracking_number: string;
};

export type ShipmentConfirmationLineItem = {
  actual: string;
  expiration: string;
  handling_unit: string;
  item_id: string;
  lot_number: string;
  name: string;
  sku: string;
  unit_of_measure: string;
};

export type Exception = {
  exception_display_name?: string;
  exception_id: string;
  exception_level: ExceptionLevel;
  exception_title?: ExceptionTitle;
  exception_type: string;
  metadata: any;
  order_id?: string;
  purchase_order_line_id?: string;
  sales_order_line_id?: string;
  status: string;
  transfer_order_line_id?: string;
};

type ExceptionLevel = "order" | "order_line";

export type ExceptionTitle =
  | "automation_hold"
  | "incomplete_address"
  | "invalid_address"
  | "invalid_channel"
  | "invalid_facilities"
  | "invalid_sku"
  | "invalid_tags"
  | "invalid_unit"
  | "invalid_shipment_type"
  | "invalid_shelf_life_requirements"
  | "fulfillment_consolidation_hold"
  | "no_supplier";

type OrderTag = {
  description: string | null;
  name: string;
  tag_id: string;
};

// TODO: Fix this. This is some weird combination of fulfillment status, operational status, order status, etc.
type KnownOrderStatus =
  | "accepted"
  | "available"
  | "backlog"
  | "backordered_hold"
  | "backordered"
  | "canceled"
  | "delivered"
  | "on_hold"
  | "open"
  | "packed"
  | "partially_backordered"
  | "partially_delivered"
  | "partially_fulfilled"
  | "partially_received"
  | "partially_shipped"
  | "pending"
  | "pick"
  | "ready_for_fulfillment"
  | "received"
  | "released"
  | "shipped"
  | "staged";

export type SignatureRequired =
  | "adult_resident_signature"
  | "adult_signature"
  | "resident_signature"
  | "signature";

type SalesOrderFinancials = {
  additional_fees: { price: string; title: string }[];
  currency_code: string;
  discount_codes: string[];
  shipping_fees: string;
  subtotal_price: string;
  tax_lines: { amount: string; rate: string; title: string }[];
  total_additional_fees: string;
  total_discounts: string;
  total_duties: string;
  total_price: string;
  total_tax: string;
};

type SalesOrderLineFinancials = {
  duties: { country_code: string; price: string }[];
  total_discounts: string;
  unit_price: string;
};
