import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import { ConfigContext } from "../../context";

const getConfigOverride = (
  { paramKey } = {
    paramKey: "config",
  },
) => {
  const configOverride = new URLSearchParams(location.search)?.get(paramKey);
  let overrides: Record<string, any> = {};
  try {
    if (configOverride) {
      overrides = JSON.parse(`${atob(configOverride)}`) as Record<string, any>;
    }
  } catch {
    console.error(
      "Error parsing config overrides, ensure that you have encoded valid JSON",
    );
  }
  return overrides;
};

/**
 * @param branchPrefix - The 'clean' path segment generated by the preview github action
 * @param appName - Name of the
 * @returns A usable preview URL
 */
const buildPreviewURL = (
  branchPrefix: string,
  appName: "shipper" | "facility" | "cx-portal",
) => `${window.location.protocol}//${branchPrefix}.stord-${appName}.pages.dev`;

export interface ConfigProviderProps<T> {
  children: React.ReactNode | ((props: T) => React.ReactNode);
  initialValue?: Record<string, any>;
}

export const ConfigProvider = <T extends {} = Record<string, any>>({
  children,
}: ConfigProviderProps<T>) => {
  const { data, error } = useQuery({
    queryKey: ["getConfig"],
    queryFn: () =>
      fetch("/config.json").then(
        (res) => res.json() as Promise<Record<string, any>>,
      ),
  });

  const currentHostname = window.location.hostname;

  const previewHosts = React.useMemo(() => {
    const isPreviewEnv = currentHostname.endsWith("pages.dev");

    // We should only have pages.dev hostnames for previews;
    // this will look something like some-clean-branch-url.stord-shipper.pages.dev
    if (!isPreviewEnv) return {};
    const branchPrefixOrTeamName = currentHostname.split(".")[0];
    return {
      SHIPPER_HOST: buildPreviewURL(branchPrefixOrTeamName, "shipper"),
      CX_HOST: buildPreviewURL(branchPrefixOrTeamName, "cx-portal"),
    };
  }, [currentHostname]);

  const config: Record<string, any> = React.useMemo(
    () => ({
      ...data,
      ...getConfigOverride({ paramKey: "config" }),
      ...previewHosts,
    }),
    [data, previewHosts],
  );

  // This should be impossible, but we'll cover it. This should only happen if someone blocks
  // config.json intentionally.
  if (error) {
    return (
      <div>
        There was an error loading the application configuration. Please reload.
      </div>
    );
  }

  // We block any children from rendering until we have a config.
  // @future option: validate the config.
  if (!data) {
    return null;
  }

  return (
    <ConfigContext.Provider value={config}>
      {typeof children === "function" ? children(config as T) : children}
    </ConfigContext.Provider>
  );
};
