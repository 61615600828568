import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import userflow from "userflow.js";
import {
  faBoxesStacked,
  faChartNetwork,
  faChartPieSimple,
  faClipboardList,
  faHouse,
  faQuestionCircle,
  faReceipt,
  faWarehouseFull,
  faGear,
  faWandMagicSparkles,
  faPlug,
  faTruck,
  faUser,
  faFileWaveform,
  faUsers,
  faBuildings,
  faRulerTriangle,
  faKey,
  faMagnifyingGlass,
  faFaceSmileHearts,
  faMoneyBills,
  faTruckFast,
  faArrowTurnDownLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { DarkMode, Kbd, Flex } from "@chakra-ui/react";

import type { NavigationItem } from "@stordco/fe-components";
import {
  useFlag,
  useUser,
  useConfig,
  useIsAdmin,
} from "@stordco/fe-components";

import { useFormatInPreferredTimezone } from "../../../hooks";
import { useNetwork, useNetworkConfigurationFlags } from "../../Network";
import { useHasNetworkPermissions } from "../../NetworkPermissionsProvider";
import { useGlobalSearch } from "../../GlobalSearchProvider";
import { useHasAccessToAutomation } from "../../../features/Automation/useHasAccessToAutomation";
import { automationGroupProps } from "../../../features/Automation/consts";
import type { AutomationGroup } from "../../../features/Automation/types";
import { useCanUseLotControls } from "../../../features/LotControl/useCanUseLotControls";
import { useUserflowInitialize } from "../../UserflowInitializer";

export const useMainNav = (): {
  primary: NavigationItem[];
  secondary: NavigationItem[];
} => {
  const { pathname } = useLocation();
  const network = useNetwork();
  const isAdmin = useIsAdmin();
  const user = useUser();
  const { ENABLE_DOMO: isDomoEnabled } = useConfig();
  const { onOpen: onOpenGlobalSearch } = useGlobalSearch();
  const formatter = useFormatInPreferredTimezone();

  const forceShowCXTypeform = useFlag(
    "force-show-consumer-experience-typeform",
  );
  const showSupplierManagement = useFlag("ui-enable-supplier-management");

  const showReferralProgramLinkFlag = useFlag("show-referral-program-link");

  const webhooksPortalFlag = useFlag("enable-webhooks-portal");

  const canUseLotControls = useCanUseLotControls();

  const hasAdminItemPermission = useHasNetworkPermissions(["items.create"]);

  const hasAdminNetworkPermission = useHasNetworkPermissions(["$self.read"]);

  const redirectPath = pathname.split("/distribution_networks/").join("");

  const {
    channel_reservations: hasChannelReservationsFeature,
    work_orders: hasWorkOrdersConfiguration,
    demand_planning_ui: hasInventoryPlanningUIFeature,
    consumer_experience: hasConsumerExperienceFeature,
    shipment_protection_admin: hasShipmentProtectionFeature,
    returns_admin: hasReturnsFeature,
  } = useNetworkConfigurationFlags();

  const { hasAccessToAutomationGroup } = useHasAccessToAutomation();

  const currentDate = formatter(new Date(), { formatStr: "yyyy-MM-dd" });

  const { isSuccess } = useUserflowInitialize();

  return useMemo(
    () => ({
      primary: [
        {
          label: "Home",
          to: `/distribution_networks/${network.network_id}/dashboard`,
          icon: faHouse,
        },
        {
          label: "Orders",
          to: `/distribution_networks/${network.network_id}/orders`,
          icon: faReceipt,
        },
        ...(hasReturnsFeature
          ? [
              {
                label: "Returns",
                to: `/distribution_networks/${network.network_id}/returns`,
                icon: faArrowTurnDownLeft,
              },
            ]
          : []),
        ...(hasWorkOrdersConfiguration
          ? [
              {
                label: "Work orders",
                to: `/distribution_networks/${network.network_id}/work_orders`,
                icon: faBoxesStacked,
              },
            ]
          : []),
        {
          label: "Inventory",
          icon: faWarehouseFull,
          children: [
            {
              label: "Overview",
              to: `/distribution_networks/${network.network_id}/inventory`,
              icon: faWarehouseFull,
            },
            {
              label: "Multichannel",
              to: `/distribution_networks/${network.network_id}/channel_management/multichannel`,
              icon: faWarehouseFull,
              rightIcon: !hasChannelReservationsFeature ? faSparkles : null,
            },
            ...(hasInventoryPlanningUIFeature || isAdmin
              ? [
                  {
                    label: "Planning",
                    to: `/distribution_networks/${network.network_id}/planning`,
                    icon: faWarehouseFull,
                    rightIcon: !hasInventoryPlanningUIFeature
                      ? faSparkles
                      : null,
                  },
                ]
              : []),
            {
              label: "Adjustments",
              to: `/distribution_networks/${network.network_id}/inventory_adjustments?adjusted_at_start_date=${currentDate}&adjusted_at_end_date=${currentDate}`,
              icon: faWarehouseFull,
            },
            ...(isAdmin
              ? [
                  {
                    label: "Reservation ledger",
                    to: `/distribution_networks/${network.network_id}/reservation_ledger_entries`,
                    icon: faWarehouseFull,
                  },
                ]
              : []),
            ...(canUseLotControls
              ? [
                  {
                    label: "Lot control",
                    to: `/distribution_networks/${network.network_id}/lot_control`,
                    icon: faWarehouseFull,
                  },
                ]
              : []),
          ],
        },
        {
          label: "Product catalog",
          to: `/distribution_networks/${network.network_id}/product_catalog`,
          icon: faClipboardList,
        },
        {
          label: "Network",
          icon: faChartNetwork,
          children: [
            {
              label: "Facilities",
              to: `/distribution_networks/${network.network_id}/network/facilities`,
              icon: faWarehouseFull,
            },
            {
              label: "Carriers",
              to: `/distribution_networks/${network.network_id}/network/service-methods`,
              icon: faWarehouseFull,
            },
            ...(showSupplierManagement
              ? [
                  {
                    label: "Suppliers",
                    to: `/distribution_networks/${network.network_id}/network/suppliers`,
                    icon: faWarehouseFull,
                  },
                ]
              : []),
          ],
        },
        ...(isDomoEnabled
          ? [
              {
                label: "Insights",
                // NOTE: If this URL changes, update the route match in `Navigation.tsx`
                to: `/distribution_networks/${network.network_id}/insights`,
                icon: faChartPieSimple,
              },
            ]
          : []),
        {
          label: "Automation",
          icon: faWandMagicSparkles,
          children: [
            {
              label: "Overview",
              to: `/distribution_networks/${network.network_id}/automation`,
              icon: null,
              end: true,
            },
            ...Object.entries(automationGroupProps)
              .filter(
                ([automationGroup, { isAddon }]) =>
                  hasAccessToAutomationGroup(
                    automationGroup as AutomationGroup,
                  ) || isAddon,
              )
              .map(([automationGroup, { label, navLabel, typeformPath }]) => ({
                label: navLabel ?? label,
                to: hasAccessToAutomationGroup(
                  automationGroup as AutomationGroup,
                )
                  ? `/distribution_networks/${network.network_id}/automation/${automationGroup}`
                  : `/distribution_networks/${network.network_id}/${typeformPath}`,
                icon: null,
                rightIcon: hasAccessToAutomationGroup(
                  automationGroup as AutomationGroup,
                )
                  ? null
                  : faSparkles,
              })),
          ],
        },
        {
          label: "Consumer experience",
          icon: faFaceSmileHearts,
          ...(hasConsumerExperienceFeature && !forceShowCXTypeform
            ? {
                children: [
                  {
                    label: "Pre-purchase",
                    to: `/distribution_networks/${network.network_id}/consumer_experience/pre-purchase`,
                    icon: faFaceSmileHearts,
                  },
                  {
                    label: "Email alerts",
                    to: `/distribution_networks/${network.network_id}/consumer_experience/email_alerts`,
                    icon: faFaceSmileHearts,
                  },
                  {
                    label: "Tracking portals",
                    to: `/distribution_networks/${network.network_id}/consumer_experience/tracking_portals`,
                    icon: faFaceSmileHearts,
                  },
                  ...(hasShipmentProtectionFeature && isAdmin
                    ? [
                        {
                          label: "Shipment protection",
                          to: `/distribution_networks/${network.network_id}/consumer_experience/shipment_protection`,
                          icon: faFaceSmileHearts,
                        },
                      ]
                    : []),
                ],
              }
            : {
                to: `/distribution_networks/${network.network_id}/consumer_experience`,
                rightIcon: faSparkles,
              }),
        },
        {
          label: "Connections",
          to: `/distribution_networks/${network.network_id}/connections`,
          icon: faPlug,
        },
        {
          label: "Request freight quote",
          to: `https://form.typeform.com/to/zsX7lQUR?typeform-source=form.typeform.com#firstname=${user.first_name}&lastname=${user.last_name}&email=${user.email}&company=${network.name}&redirectpath=${redirectPath}`,
          icon: faTruck,
          isExternal: true,
        },
        {
          label: "Freight customer portal",
          to: "https://stordllc123.my.site.com/customercommunity/login?ec=302&startURL=%2Fcustomercommunity%2Fs%2F",
          icon: faTruckFast,
          isExternal: true,
        },
      ],
      secondary: [
        ...(showReferralProgramLinkFlag
          ? [
              {
                label: "Referral program",
                icon: faMoneyBills,
                to: "https://www.stord.com/getstord/referral",
                isExternal: true,
                color: "volt",
                _hover: {
                  color: "volt",
                },
              },
            ]
          : []),
        {
          label: "Search",
          icon: faMagnifyingGlass,
          rightElement: (
            <DarkMode>
              <Flex gap={1}>
                <Kbd>{navigator.userAgent.includes("Mac") ? "⌘" : "Ctrl"}</Kbd>

                <Kbd>K</Kbd>
              </Flex>
            </DarkMode>
          ),
          onClick: onOpenGlobalSearch,
        },
        {
          label: "Support",
          icon: faQuestionCircle,
          ...(isSuccess
            ? { onClick: () => userflow.toggleResourceCenter() }
            : {
                to: "https://help.stord.com/support/solutions",
                isExternal: true,
              }),
        },
        {
          label: "Settings",
          icon: faGear,
          children: [
            {
              label: "Profile",
              to: `/distribution_networks/${network.network_id}/settings/profile`,
              icon: faUser,
            },
            {
              label: "Members",
              to: `/distribution_networks/${network.network_id}/settings/members?status=active`,
              icon: faUsers,
            },
            ...(hasAdminNetworkPermission
              ? [
                  {
                    label: "Configuration",
                    to: `/distribution_networks/${network.network_id}/settings/config`,
                    icon: faBuildings,
                  },
                ]
              : []),
            {
              label: "Activity log",
              to: `/distribution_networks/${network.network_id}/settings/activity_log`,
              icon: faFileWaveform,
            },
            ...(hasAdminItemPermission
              ? [
                  {
                    label: "Data",
                    to: `/distribution_networks/${network.network_id}/settings/data`,
                    icon: faRulerTriangle,
                  },
                ]
              : []),
            {
              label: "API keys",
              to: `/distribution_networks/${network.network_id}/settings/api_keys?status=active`,
              icon: faKey,
            },
            ...(webhooksPortalFlag
              ? [
                  {
                    label: "Webhooks",
                    to: `/distribution_networks/${network.network_id}/settings/webhooks`,
                    icon: faKey,
                  },
                ]
              : []),
          ],
        },
      ],
    }),
    [
      network.network_id,
      network.name,
      hasWorkOrdersConfiguration,
      hasChannelReservationsFeature,
      hasInventoryPlanningUIFeature,
      isAdmin,
      currentDate,
      canUseLotControls,
      showSupplierManagement,
      isDomoEnabled,
      hasConsumerExperienceFeature,
      forceShowCXTypeform,
      hasShipmentProtectionFeature,
      user.first_name,
      user.last_name,
      user.email,
      redirectPath,
      showReferralProgramLinkFlag,
      onOpenGlobalSearch,
      hasAdminNetworkPermission,
      hasAdminItemPermission,
      webhooksPortalFlag,
      hasAccessToAutomationGroup,
      isSuccess,
      hasReturnsFeature,
    ],
  );
};
