import { useContext, useMemo } from "react";

import NetworkContext from "./NetworkContext";
import type { NetworkConfiguration } from "./queries";

export default function useNetwork() {
  const value = useContext(NetworkContext);

  if (!value) {
    throw new Error(
      `${useNetwork.name} must be called within a NetworkProvider`,
    );
  }

  return value;
}

export const useNetworkId = () => {
  const network = useNetwork();
  return network.network_id;
};

export type BackordersConfig = Extract<
  NetworkConfiguration,
  { name: "backorders" }
>;

export type SplitOrderConfig = Extract<
  NetworkConfiguration,
  { name: "split_order" }
>;

export type HoldOrderConfig = Extract<
  NetworkConfiguration,
  { name: "hold_order" }
>;

export type OrderRoutingConfig = Extract<
  NetworkConfiguration,
  { name: "order_routing" }
>;

export type ConsolidateFulfillmentsConfig = Extract<
  NetworkConfiguration,
  { name: "consolidate_fulfillments" }
>;

export function useNetworkConfiguration<T extends NetworkConfiguration["name"]>(
  configName: T,
) {
  const network = useNetwork();

  return network.network_configurations.find(
    (config) => config.name === configName,
  ) as Extract<NetworkConfiguration, { name: T }>;
}

/** Get the `enabled` property of a network configuration. */
export function useNetworkConfigurationFlag(
  configName: NetworkConfiguration["name"],
) {
  return useNetworkConfigurationFlags()[configName];
}

export function useNetworkConfigurationFlags() {
  const network = useNetwork();

  return useMemo(() => {
    return network.network_configurations.reduce(
      (flags, config) => ({
        ...flags,
        [config.name]: config.enabled,
      }),
      {} as Record<NetworkConfiguration["name"], boolean>,
    );
  }, [network]);
}
