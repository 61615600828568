import type { RefObject } from "react";
import { useMemo, useRef } from "react";
import { Button, Center, Flex, Text, useDimensions } from "@chakra-ui/react";
import Confetti from "react-confetti";
import { AnimatePresence, motion } from "framer-motion";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";

import {
  Card,
  FaIcon,
  Loader,
  pluralize,
  qs,
  useDatadogRUM,
  useSearchParams,
} from "@stordco/fe-components";

import {
  useNetworkConfigurationFlag,
  useNetworkId,
} from "../../../../components/Network";
import { exceptionLabels } from "../../../Orders/exception-labels";
import { CONFETTI_COLORS, drawConfetti } from "../../../../components/Confetti";

import "./scroll-shadows.css";
import { useWidgetData } from "./use-widget-data";
import { AlertLink } from "../../AlertLink";
import type { ExceptionTitle } from "../../../../hooks/queries/useOrderDetails";
import {
  useGetReturnSummary,
  useGetShipmentProtectionClaimSummary,
} from "../../../../hooks/cxApi/cxComponents";

const MotionAlertLink = motion(AlertLink);

export function Exceptions() {
  const networkId = useNetworkId();
  const [params] = useSearchParams();

  const hasReturnsFeature = useNetworkConfigurationFlag("returns_admin");

  const { data, isLoading, isError, refetch } = useWidgetData(
    "new-order-exceptions",
  );
  const { data: shipmentProtectionClaims } =
    useGetShipmentProtectionClaimSummary({
      pathParams: {
        externalReference: networkId,
      },
    });

  const { data: returnSummary } = useGetReturnSummary(
    {
      pathParams: {
        externalReference: networkId,
      },
    },
    {
      enabled: hasReturnsFeature,
    },
  );

  const sortedCounts = useMemo(() => {
    if (!data) {
      return [];
    }

    const exceptions: Array<{
      key: string;
      colorScheme: "mango" | "red" | "magenta";
      to: string;
      badgeLabel: string;
      count: number;
      text: string;
    }> = [
      {
        key: "backordered",
        colorScheme: "mango" as const,
        to: qs.stringifyUrl({
          url: `/distribution_networks/${networkId}/orders`,
          query: {
            type: "sales",
            status: "backordered",
            ...params,
          },
        }),
        badgeLabel: "Backordered",
        count: data.backordered_count,
        text: "",
      },
      ...data.exception_counts.map(({ exception_title, count }) => ({
        key: exception_title,
        colorScheme: "red" as const,
        to: qs.stringifyUrl({
          url: `/distribution_networks/${networkId}/orders`,
          query: {
            type: "sales",
            exception: exception_title,
            ...params,
          },
        }),
        // TODO: For now, all buckets besides "backordered" are exceptions that cause orders to be "On hold".
        // if this changes in the future, update the logic here for determining what badge to show
        badgeLabel: "On hold",
        count,
        text: exceptionLabels[exception_title as ExceptionTitle],
      })),
    ].sort((a, b) => b.count - a.count);

    // Pending claims aren't exactly an order exception, but this is the simplest way to keep the layout and
    // ensure status chips are sorted correctly and grouped.
    exceptions.push({
      key: "shipment_protection_pending_claims",
      colorScheme: "mango" as const,
      to: qs.stringifyUrl({
        url: `/distribution_networks/${networkId}/orders`,
        query: {
          type: "sales",
          shipment_protection_claim_status: "pending",
          ...params,
        },
      }),
      badgeLabel: pluralize(
        shipmentProtectionClaims?.data.pending ?? 0,
        "Pending claim",
        "Pending claims",
      ),
      count: shipmentProtectionClaims?.data.pending ?? 0,
      text: "",
    });

    if (hasReturnsFeature) {
      exceptions.push({
        key: "returns",
        colorScheme: "magenta" as const,
        to: qs.stringifyUrl({
          url: `/distribution_networks/${networkId}/returns`,
          query: {
            status: "requested",
            ...params,
          },
        }),
        badgeLabel: pluralize(
          returnSummary?.data?.requested ?? 0,
          "Return Request",
          "Return Requests",
        ),
        count: returnSummary?.data?.requested ?? 0,
        text: "",
      });
    }

    return exceptions.filter(({ count }) => count > 0);
  }, [
    data,
    networkId,
    params,
    shipmentProtectionClaims,
    hasReturnsFeature,
    returnSummary,
  ]);

  const ref = useRef<HTMLElement>(null);
  const datadogRUM = useDatadogRUM();

  return (
    <Card ref={ref} minHeight="72px" position="relative">
      {isLoading ? (
        <Center position="absolute" inset={0}>
          <Loader size="lg" />
        </Center>
      ) : isError ? (
        <Center position="absolute" inset={0} gap={4}>
          <FaIcon icon={faTriangleExclamation} color="gray.500" size="md" />

          <Text textStyle="label">Error fetching data</Text>

          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => {
              refetch();
            }}
          >
            Retry
          </Button>
        </Center>
      ) : (
        <>
          {!sortedCounts.length ? (
            <>
              <Flex flex={1} alignItems="center" justifyContent="center">
                <Text>Orders are moving smoothly! 🎉</Text>
              </Flex>

              <ExceptionsConfetti containerRef={ref} />
            </>
          ) : (
            <Card.PaddedContent pt={3} px={3} pb={0}>
              <Flex
                className="horizontal-scroll-shadows"
                gap={2}
                overflowX="auto"
              >
                <AnimatePresence>
                  {sortedCounts.map(
                    ({ key, colorScheme, to, badgeLabel, count, text }) => (
                      <MotionAlertLink
                        key={key}
                        to={to}
                        count={count}
                        badgeLabel={badgeLabel}
                        text={text}
                        colorScheme={colorScheme}
                        onClick={() => {
                          datadogRUM?.addAction("dashboard:view_orders", {
                            type: "exception",
                            exception: key,
                            count,
                          });
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      />
                    ),
                  )}
                </AnimatePresence>
              </Flex>
            </Card.PaddedContent>
          )}
        </>
      )}
    </Card>
  );
}

function ExceptionsConfetti({
  containerRef,
}: {
  containerRef: RefObject<HTMLElement>;
}) {
  const dimensions = useDimensions(containerRef);

  if (!dimensions) {
    return null;
  }

  return (
    <Confetti
      width={dimensions.borderBox.width}
      height={dimensions.borderBox.height}
      opacity={0.5}
      colors={CONFETTI_COLORS}
      numberOfPieces={400}
      recycle={false}
      drawShape={drawConfetti}
      initialVelocityY={4}
      confettiSource={{
        x: 0,
        y: -10,
        w: dimensions.borderBox.width,
        h: 0,
      }}
    />
  );
}
